import { storage } from "~/utils/consts";

export default defineNuxtRouteMiddleware(async () => {
  /**
   * Пока оставляем только на клиенте. т.к. на ssr ловим неочевидные проблемы из-за legacy кода по useTransport with JWT
   */
  if (import.meta.server) return;

  const jwt = useCookie<IJWT | null>(storage.jwt, { httpOnly: false });
  const userStore = useUserStore();

  if (jwt.value && userStore.status.profile !== "success") {
    await userStore.getProfile();
  }
});
