import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45profile_45global from "/app/src/middleware/01.profile.global.ts";
import _02_45sentry_45global from "/app/src/middleware/02.sentry.global.ts";
import _03_45redirects_45global from "/app/src/middleware/03.redirects.global.ts";
import _04_45statistic_45global from "/app/src/middleware/04.statistic.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45profile_45global,
  _02_45sentry_45global,
  _03_45redirects_45global,
  _04_45statistic_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}