import { onMounted } from "vue";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { fbaseConfig } from "~/store/push";
import { storage } from "~/utils/consts";

const getServiceWorkerHelpers = async () => {
  // Во всей этой утилке мы себе ставим цель обернуть нужные части апихи сервис воркера
  // Для удобства пользования. Чтобы не делать много лишних проверок далее по коду в "бизнес логике".
  // Если сервис воркер не поддерживается -- у нас просто функции пустышки, которые можно вызывать но ничего не будет.
  // Если же у нас есть намёк на поддержку SW сделаем всё возможное чтобы вызов функции был успешным, но не обязательно "срочным".
  if (typeof navigator === "undefined" || !navigator?.serviceWorker) {
    return {
      showNotification: (_title: string, _options: NotificationOptions) => {},
      postMessage: (_message: { type: string; [key: string]: unknown; }) => {},
    };
  }

  const serviceWorker = navigator.serviceWorker;

  // Имейте ввиду serviceWorker.ready никогда не reject-ит,
  // но может пройти продолжительное время пока не резолвнется.
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/ready
  // По сути это мы ждём активной регистрации сервис воркера тут.
  // А сама фактическая регистрация у нас либо в пушах, либо в пва, либо чёрти где :-(
  // TODO: (Это надо будет порефачить потом тоже)
  // Про регистрацию см. функцию serviceWorkerInit поиском по проекту
  const registration = await serviceWorker.ready;

  const showNotification = (title: string, options: NotificationOptions) => {
    registration.showNotification(title, options);
  };
  const postMessage = (message: { type: string; [key: string]: unknown; }) => {
    serviceWorker.controller?.postMessage(message);
  };

  return {
    showNotification,
    postMessage,
  };
};

const useFirebaseInit = () => {
  if (!import.meta.client) {
    return;
  }

  const deviceId = useCookie(storage.uniqDeviceID, {
    readonly: true,
  });
  watch(deviceId, async (newDeviceId) => {
    // чтобы наверняка самый свежачок deviceId был в сервис воркере для отправки аналитики по пушам
    // в т.ч. сервис воркер сохранит его в indexed db на случай отправок даже когда все вкладки браузер закрыты
    // P.S. сейчас баг, пеовые сообщения могут не дойти,
    // т.к. мы сервис воркер передёргиваем из нескольких мест,
    // и похоже это приводит к тому что некоторые сообщения встают в очередь на старый инстанс воркера,
    // а затем с ним умирают, когда его перезатирает новый инстанс...
    (await getServiceWorkerHelpers()).postMessage({
      type: "MESSAGE_DEVICE_ID",
      deviceId: newDeviceId || "omit empty",
    });
  }, {
    immediate: import.meta.client,
  });

  onMounted(async () => {
    const app = initializeApp(fbaseConfig);
    const permissionStatus = navigator.permissions
      ? await navigator.permissions.query({
        name: "notifications",
      })
      : undefined;

    if (permissionStatus?.state !== "granted") {
      return;
    }

    const messaging = getMessaging(app);
    onMessage(messaging, async (message) => {
      // я не знаю зачем мы на каждое сообщение заного получаем service worker,
      // возможно мы уже дуреем от этой прикормки.
      // Но тут так было, решил пока примерно также оставить.
      // Потому-что мб это хитрый багфикс на случай если регистрация воркера успела смениться между сообщениями?
      const { postMessage, showNotification } = await getServiceWorkerHelpers();
      // на всякий случай ещё раз закинем новую девайс айдишку,
      // мало-ли воркер не был готов принять сообщение пока мы инициализировались
      // Вообще этого не должны делать, там выше вотчер должен ок закидывать айди только по факту изменения.
      // И getServiceWorkerHelpers должен гарантировать доступность воркера до того как резолвнутся.
      // Но сейчас это сбоит т.к. вызываем множественные регистрации воркера чёрти где,
      // тем самым serviceWorker.ready резолвится либо прошлой регистрацией, либо регистрация успевает перезатерется и gg wp ez
      postMessage({
        type: "MESSAGE_DEVICE_ID",
        deviceId: deviceId.value || "omit empty",
      });
      postMessage({
        ...message,
        type: "MESSAGE_PUSH_RECEIVED",
      });
      showNotification(
        message.notification?.title || "",
        {
          body: message.notification?.body || "",
          data: {
            ...message,
          },
        },
      );
    });
  });
};

export default useFirebaseInit;
