import type { FetchError } from "ofetch";
import { defineStore } from "pinia";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import { getHeaders } from "~/composables/useTransport";
import { HttpCodes, urls } from "~/api/config";
import { storage } from "~/utils/consts";
import type { IJWT } from "~/utils/jwt";
import { Routes } from "~/utils/routes";
import { useUserStore } from "~/store/user";
import { APP_COOKIES_MAX_AGE_YEAR } from "~/constants/app";

interface IAuthState {
  authorized: boolean;
  registered: boolean;
}

interface IAuthStatus {
  login: "initial" | "pending" | "success" | "error";
}

export const useAuthStore = defineStore("auth", () => {
  const config = useRuntimeConfig();
  const userStore = useUserStore();
  const router = useRouter();
  const { send } = AnalyticManager;
  const magnitIDCode = useCookie(storage.magnitIDCode, { maxAge: 3600 });
  const JWT = useCookie<IJWT | null>(storage.jwt, { maxAge: APP_COOKIES_MAX_AGE_YEAR });
  const ksid = useCookie(storage.ksid, { maxAge: APP_COOKIES_MAX_AGE_YEAR });

  let refreshPromise: Promise<void> | null = null;

  const state = reactive<IAuthState>({
    authorized: false,
    registered: false,
  });

  const status = reactive<IAuthStatus>({
    login: "initial",
  });

  const getMagnitId = () => magnitIDCode.value || "";
  const setMagnitId = (code: string) => {
    magnitIDCode.value = code;
  };

  const getRegister = () => state.registered;
  const setRegister = (value: boolean) => {
    state.registered = value;
  };

  const setTokens = (access: string, refresh: string) => {
    JWT.value = { access, refresh };
    setAuth(true);
  };

  const setAuth = (value: boolean) => {
    state.authorized = value;
  };

  async function logout() {
    status.login = "initial";

    setAuth(false);
    JWT.value = null;
    ksid.value = null;

    await router.push(Routes.Landing);
  }

  async function login() {
    try {
      const data = await useDirectFetch<{
        accessToken: string;
        refreshToken: string;
      }>(urls.auth.login, {
        gateway: "magnit-id",
        method: "POST",
        body: {
          aud: "V1-web",
          magnitIDCode: getMagnitId(),
        },
      });

      if (data) {
        setMagnitId("");
        setTokens(data.accessToken, data.refreshToken);

        // @TODO: Костылище для дополнительной регистрации юзера в CRM
        await userStore.getQR();
        status.login = "success";
        await router.push({ path: Routes.Main });
      }
    } catch (e) {
      const error = e as FetchError;
      if (
        error?.statusCode &&
        error.statusCode >= HttpCodes.Error4xx
      ) {
        if (error.data.code) {
          send("Auth:Validation:Error:View", {
            error_type: error.data.code,
          });
        }

        await router.push(Routes.Profile);

        status.login = "initial";
      }
    }
  }

  async function refresh() {
    if (!refreshPromise) {
      refreshPromise = new Promise((resolve, reject) => {
        $fetch<{
          accessToken: string;
          refreshToken: string;
        }>(urls.auth.refresh, {
          baseURL: "/magnit-id",
          headers: getHeaders({}, config.public.version as string),
          method: "POST",
          body: {
            aud: "V1-web",
            refreshToken: JWT.value?.refresh,
          },
        }).then(
          (data) => {
            if (data.accessToken) {
              setTokens(data.accessToken, data.refreshToken);
              resolve();
            }
          },
          async (error: FetchError) => {
            if (error?.statusCode) {
              if (error.statusCode === HttpCodes.Unauthorized) {
                await logout();
                return;
              }

              if (error.statusCode >= HttpCodes.Error4xx) {
                status.login = "initial";

                reject(new Error("refresh request error"));
              }
            }
          },
        );
      });
    }

    return refreshPromise.finally(() => {
      refreshPromise = null;
    });
  }

  return {
    getMagnitId,
    setMagnitId,
    getRegister,
    setRegister,
    setAuth,
    login,
    logout,
    refresh,
    state,
    status,
  };
});
