import { AnalyticManager } from "@magnit/analytic-events/src/manager";

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) return;

  const { send } = AnalyticManager;

  if (to.path === Routes.Profile) {
    if (from.path === Routes.ProfileStars) {
      send("Profile:Stars:Back");
    }
    if (from.path === Routes.ProfileCardsAdd) {
      send("Profile:CardsAdd:Back");
    }
    if (from.path === Routes.ProfileLegal) {
      send("Profile:Legal:Back");
    }
  }

  if (to.path === Routes.Login) {
    if (from.path === Routes.Registration) {
      send("Register:Personal:Back");
    }
  }
});
