import type { IAbExperiments, IAbExperimentsRequestBody } from "~/typings/api/experiments";
import { useAuthStore } from "~/store/auth";

export default () => {
  const authStore = useAuthStore();

  const fetchExperiments = (
    splitId: string, // aka userId или что-то подобное чем аналитика может однозначно стабильно различать пользователя
    namespace: string,
  ) => useDirectFetch<IAbExperiments>(urls.experiments.experiments, {
    method: "POST",
    permissions: {
      // что примечательно на gateway этот метод и без авторизации работает
      // Однако, если не передавать JWTшку то null нам а не экспы
      // Причем похоже без JWT даже запрос не кидает... Походу мы где-то намудрили...
      jwt: authStore.state.authorized,
    },
    body: {
      "split-id": splitId,
      namespace,
    } satisfies IAbExperimentsRequestBody,
  });

  return {
    fetchExperiments,
  };
};
