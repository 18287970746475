// TODO: отрефачить serviceWorkerInit, хочется чтобы он делал ещё и то что getServiceWorkerHelpers
// Т.е. эта утилка должна убедится, что у нас активный воркер,
// если нет то регнуть,
// дождаться пока воркер станет активным,
// затем только резолвиться.
// И впринципе избегать множественных одновременных вызовов себя,
// чтобы предотвратить конфликтные перезаписи-переактивации одного и того-же воркера.

import { captureException } from "@sentry/nuxt";

const serviceWorkerInit = async () => {
  if (typeof navigator === "undefined") {
    return;
  }
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js",
        { type: "module" },
      );
      if (registration) {
        // eslint-disable-next-line no-console
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope,
        );
        await registration.update();
        await navigator.serviceWorker.ready;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("ServiceWorker registration failed: ", err);
      captureException(err);
    }
  }
};

export default serviceWorkerInit;
