enum HttpCodes {
  Success = 200,
  NoContent = 204,

  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,

  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Error4xx = 400,
  Error5xx = 500,
}

enum ErrorCodes {
  Captcha = "CaptchaError",
  CardMergeBlocked = "cardMergeBlocked",
  IncompleteProfile = "incompleteProfile",
  EmailForbidden = "emailForbiddenToUse",
  ValidationError = "validationError",
  UserNotFound = "user_not_found",
}

/**
 * @swagger web-gateway: https://web-gateway.dev.ya.magnit.ru/docs/index.html
 * @swagger magnit-id: https://id.dev.ya.magnit.ru/docs/index.html
 */
const urls = {
  "otp": {
    /**
     * Генерация и отправка OTP кода по номеру телефона
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDSendOTPV1
     */
    getCode: "/v1/auth/otp",
    /**
     * Проверка OTP и получение MagnitID Code кода для авторизации нового пользователя
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDCheckOTPV1
     */
    checkCode: "/v1/auth/otp/check",
  },
  "auth": {
    /**
     * Авторизация
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDGenerateTokenV1
     */
    login: "/v1/auth/token",
    /**
     * Обновление JWT токена
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDRefreshTokenV1
     */
    refresh: "/v1/auth/token/refresh",
  },
  "user": {
    /**
     * Получаем информацию для отрисовки QR-кода
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/cards-v2/cardsV2GetV1UserTotp
     */
    qrcode: "/v1/user/totp",
    /**
     * Создаем виртуальную карту
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/cards-v2/cardsV2PostV1UserVirtualCard
     */
    virtualCardCreate: "/v1/user/virtual-card",
    /**
     * Get user balance information
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/balances-v2/balancesV2GetV3UserBalance
     */
    balance: "/v3/user/balance",
    /**
     * Получаем карточки пользователя
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/cards-v2/cardsV2GetV3UserCards
     */
    cards: "/v3/user/cards",
    /**
     * Смерживаем вертуальную и физическую карты
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/cards-v2/cardsV2PostV2UserCardMerge
     */
    cardMerge: "/v2/user/card/merge",
    /**
     * Подтверждение e-mail
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDEmailConfirmV1
     */
    emailConfirmation: "/v1/profile/email-confirm",
    /**
     * Регистрация нового пользователя
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDRegisterProfileByMagnitIDCodeV1
     */
    register: "/v3/profile/register/magnit-id-code",
    /**
     * Получаем профиль пользователя или обноваляем (patch)
     * https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDGetProfileV1
     */
    profile: "/v1/profile",
    /**
     * Получение/изменение разрешений пользователя на коммуникации
     * @swagger https://omni-web.k8s.dev.mos.corp/docs#/cuscom-v2/cuscomV2GetCuscomV2UserPermissions
     */
    permissions: "/cuscom/v2/user/permissions",
    /**
     * Сохраняем push-токен и передаем состояние системного разрешения на показ уведомлений на сервер Cuscom
     * https://omni-web.k8s.dev.mos.corp/docs#/cuscom-v2/cuscomV2PostCuscomV3UserDeviceEnroll
     */
    enrollDevice: "/cuscom/v3/user-device/enroll",
  },
  "transactions": {
    /**
     * Получение списка транзакций
     * https://omni-web.k8s.dev.mos.corp/docs#/balance/balanceGetV3UserTransactions
     */
    list: "/v3/user/transactions",
    /**
     * Получение транзакции по ID
     * https://omni-web.k8s.dev.mos.corp/docs#/balance/balanceGetV2UserTransactionsByTransactionId
     */
    item: "/v2/user/transactions",
  },
  "content": {
    // https://omni-web.k8s.prod.mos.corp/docs#/configurator-api/configuratorApiGetV1V1MarketMainPageAdfox
    mainPageBanners: "/v1/v1-market/main-page/adfox",
  },
  "coupons": {
    /**
     * Получение всех купонов
     * https://omni-web.k8s.dev.mos.corp/docs#/coupons-api/couponsApiGetV2UserCoupons
     */
    list: "/v2/user/coupons",
    /**
     * Активация купона
     * https://omni-web.k8s.dev.mos.corp/docs#/offers/offersPostV2OffersOptIn
     */
    activate: "/v2/offers/opt-in",
    /**
     * Отметить купон как любимый/нелюбимый
     * https://omni-web.k8s.dev.mos.corp/docs#/coupons-api/couponsApiPostV2UserCouponsFavorite
     */
    favorite: "/v2/user/coupons/favorite",
  },
  "experiments": {
    /**
     * Получение экспериментов платформы A/B тестирования
     * https://omni-web.k8s.dev.mos.corp/docs#/ab/abPostAbV1ExperimentsAndFeatures
     */
    experiments: "/ab/v1/experiments-and-features",
  },
  "personalPromotions": {
    /**
     * Получение персональных предложений
     * https://omni-web.k8s.dev.mos.corp/docs#/promoter/promoterGetV2UserPersonalPromotions
     */
    list: "/v2/user/personal-promotions",

    /**
     * Получение деталей по персональному предложению
     * https://omni-web.k8s.dev.mos.corp/docs#/promoter/promoterGetV1UserPersonalPromotionsByPromotionCode
     */
    detail: "/v1/user/personal-promotions/",

    /**
     * Активация персонального предложения
     * https://omni-web.k8s.dev.mos.corp/docs#/offers/offersPostV3OffersOptIn
     */
    activate: "/v3/offers/opt-in",
  },
  "goods": {
    /**
     * Поиск товаров по категориям или по поисковой строке (обновленный контракт с поддержкой Маркета)
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffPostV2GoodsSearch
     */
    products: "/v2/goods/search",
    /**
     * Получаем информации о товаре
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffGetV2GoodsByGoodIdStoresByStoreCode
     */
    product: "/v2/goods/",
    /**
     * Получение дерева категорий.
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffGetV3CategoriesStoreByStoreCode
     */
    categories: "/v3/categories/store",
    /**
     * Получение фильтров для товарного каталога
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffPostV1GoodsFilters
     */
    filters: "/v1/goods/filters",
  },

  "userReviews": {
    /**
     * Получить анкету для пользователей по ID анкеты
     * @link: http://omni-web.k8s.dev.mos.corp/docs#/user-reviews/userReviewsGetV2PollByPollId
     */
    poll: "/v2/poll",
    /**
     * Сохранить фидбэк (ответы на анкету)
     * @link: http://omni-web.k8s.dev.mos.corp/docs#/user-reviews/userReviewsPostV2ObjectFeedback
     */
    objectFeedback: "/v2/object/feedback",
    /**
     * Получить анкету для пользователя, созданную на основе покупок
     * @link: http://omni-web.k8s.dev.mos.corp/docs#/user-reviews/userReviewsGetV2UserPoll
     */
    userPoll: "/v2/user/poll",
    /**
     * Обозначить, что анкета, созданная на основе покупок, была показану пользователю
     * @link: http://omni-web.k8s.dev.mos.corp/docs#/user-reviews/userReviewsPostV1UserPollDisplayed
     */
    userPollDisplayed: "/v1/user/poll/displayed",
    /**
     * Проверяет, надо ли отобразить кнопки "оценить" для товара или магазина в детализации истории покупок.
     * @link: http://omni-web.k8s.dev.mos.corp/docs#/user-reviews/userReviewsGetV1TransactionByTransactionIdPoll
     */
    transactionPoll: (id: number) => `/v1/transaction/${id}/poll`,
    /**
     * Список оценок объекта
     * @link: http://omni-web.k8s.dev.mos.corp/docs#/user-reviews/userReviewsGetV1ObjectByObjectIdScores
     */
    objectScores: (id: string) => `/v1/object/${id}/scores`,
  },

  "store-search": {
    /** Получение списка магазинов по поисковой строке.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/store-search/storeSearchPostV1StoreSearchAddress
     */
    address: "/v1/store-search/address",
    /** Получение списка магазинов по геолокации.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/store-search/storeSearchPostV1StoreSearchGeo
     */
    geo: "/v1/store-search/geo",
    /** Получение магазина по его коду.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/store-search/storeSearchGetV1StoreSearchByStoreCode
     */
    store: "/v1/store-search",
  },
};

export { urls, HttpCodes, ErrorCodes };
