import type { FetchError } from "ofetch";
import { useRouter } from "vue-router";
import { ErrorCodes, HttpCodes } from "~/api/config";
import { storage } from "~/utils/consts";
import { privateRoutes, Routes } from "~/utils/routes";
import { APP_COOKIES_MAX_AGE_YEAR } from "~/constants/app";

const useResponseCheck = () => {
  const router = useRouter();
  const route = useRoute();
  const cookieIncompleteProfile = useCookie(storage.incompleteProfile, { maxAge: APP_COOKIES_MAX_AGE_YEAR });

  const hasError = async (error: FetchError) => {
    /**
     * ErrorCodes.UserNotFound возвращается в ручке получения баланса.
     * Не совсем верно проверять дорегистрацию по ней, но других вариантов для фикса в моменте нет
     */
    if (
      error.statusCode === HttpCodes.UnprocessableEntity &&
      [ErrorCodes.IncompleteProfile, ErrorCodes.UserNotFound].includes(error.data?.code)
    ) {
      cookieIncompleteProfile.value = "1";

      if (privateRoutes.includes(route.path)) {
        await router.push(Routes.ProfileIncomplete);
      }

      return true;
    }

    return false;
  };

  return {
    hasError,
  };
};

export default useResponseCheck;
