import { urls } from "./config";
import type {
  IStoreSearchAddressBody,
  IStoreSearchAddressResponse,
  IStoreSearchGeoBody,
  IStoreSearchGeoResponse,
  IStoreSearchStoreResponse,
} from "~/typings/api";

export default () => {
  const fetchAddressSearch = (
    body: Partial<IStoreSearchAddressBody> = {},
  ) => {
    return useDirectFetch<IStoreSearchAddressResponse>(
      urls["store-search"].address,
      {
        method: "POST",
        body,
      },
    );
  };
  const fetchGeoSearch = (body: Partial<IStoreSearchGeoBody> = {}) => {
    return useDirectFetch<IStoreSearchGeoResponse>(urls["store-search"].geo, {
      method: "POST",
      body,
    });
  };
  const fetchStoreByCode = (code: string) => {
    return useDirectFetch<IStoreSearchStoreResponse>(
      `${urls["store-search"].store}/${code}`,
    );
  };

  return {
    fetchAddressSearch,
    fetchGeoSearch,
    fetchStoreByCode,
  };
};
