import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Page:Error404:View": {
    ya: {
      eventName: "404error",
    },
  },
  "Error:View": {
    ya: {
      eventName: "error_view",
      block: "error",
      page: "",
      error_type: "", // 500 | 404
      error_message: "",
    },
  },
};
