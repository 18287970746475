import { defineStore } from "pinia";
import { reactive, ref, watch, onMounted } from "vue";
import { useLocalStorage } from "@vueuse/core";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import { FetchError } from "ofetch";
import { captureException } from "@sentry/nuxt";
import { HttpCodes, ErrorCodes, urls } from "~/api/config";
import useResponseCheck from "~/composables/useResponseCheck";
import { useAuthStore } from "~/store/auth";
import { getFormattedYYYYMMDD } from "~/utils/dates";
import { storage } from "~/utils/consts";
import type { IUserPermissionsResponse } from "~/typings/api/user";
import type { IUserPermissions, IUserPermissionsKeys } from "~/typings/user";
import { fetchPermissions, fetchUpdatePermissions } from "~/api/user";
import { renameKeys } from "~/utils/object";

interface IUserProfile {
  userId?: string;
  birthDate?: Date;
  email: string;
  firstName: string;
  isEmailConfirmed: boolean;
  phone: string;
  cardNumber: string;
}

interface IUserCard {
  id: string;
  identifierNo: string;
  isIdentifierTypeVirtual: boolean;
  status: string;
}

interface IUserBalanceItems {
  points: number;
  type: string;
  visibleForCustomer: boolean;
}

interface IUserBalance {
  isCache: boolean;
  priority: number;
  totalExpressPoints: number;
  totalPointBalance: number;
  items: IUserBalanceItems[];
}

type IStoreStatus = "initial" | "pending" | "success" | "error";

interface IUserStatus {
  balance: IStoreStatus;
  balanceError: string | null;
  cardMerge: IStoreStatus;
  cardMergeError: string | null;
  cardsList: IStoreStatus;
  delete: IStoreStatus;
  emailConfirmation: IStoreStatus;
  profile: IStoreStatus;
  profileUpdate: IStoreStatus;
  qrcode: IStoreStatus;
  qrcodeError: string | null;
  register: IStoreStatus;
  permissions: IStoreStatus;
  permissionsUpdate: IStoreStatus;
}

interface IMagnitIdValidationError {
  field: string;
  errCode: string;
}
export interface IMagnitIdRegistrationError {
  code: string;
  debugMessage: string;
  message: string;
  validationErrors: IMagnitIdValidationError[];
}

type IProfileKeys = keyof IUserProfile;

const QR_TIMEOUT = 5 * 60 * 1000;

export const useUserStore = defineStore("user", () => {
  const { hasError } = useResponseCheck();
  const { send } = AnalyticManager;

  const savedQrCode = useLocalStorage<{
    code: string;
  }>(storage.qrcode, {
    code: "",
  });

  const savedLoyaltyBalance = useLocalStorage(storage.bonuses, "");

  const savedUserId = useLocalStorage<{
    uuid: string;
  }>(storage.uuid, {
    uuid: "",
  });

  const profile = ref<IUserProfile>({
    userId: undefined,
    birthDate: undefined,
    email: "",
    isEmailConfirmed: false,
    firstName: "",
    phone: "",
    cardNumber: "",
  });

  const cards = ref<IUserCard[]>([]);

  const permissions = ref<IUserPermissions>({
    isPushPersPermitted: false,
    isPushMassPermitted: false,
    isPushContentPermitted: false,
    isEmailPermitted: false,
    isSmsPermitted: false,
    isSocialPermitted: false,
    isCallsPermitted: false,
    isDigitalReceiptPermitted: false,
  });

  const isAnyPushPermitted = computed(() =>
    Boolean(
      permissions.value.isPushPersPermitted ||
      permissions.value.isPushMassPermitted ||
      permissions.value.isPushContentPermitted,
    ),
  );

  const qrcode = ref("");

  const balance = ref<IUserBalance>({
    isCache: false,
    priority: 0,
    totalPointBalance: 0,
    totalExpressPoints: 0,
    items: [],
  });

  const status = reactive<IUserStatus>({
    balance: "initial",
    balanceError: null,
    cardsList: "initial",
    cardMerge: "initial",
    cardMergeError: null,
    delete: "initial",
    emailConfirmation: "initial",
    profile: "initial",
    profileUpdate: "initial",
    register: "initial",
    permissions: "initial",
    permissionsUpdate: "initial",
    qrcode: "initial",
    qrcodeError: null,
  });

  onMounted(() => {
    if (savedQrCode.value.code) {
      qrcode.value = savedQrCode.value.code;
    }
    if (savedLoyaltyBalance.value) {
      try {
        balance.value = JSON.parse(savedLoyaltyBalance.value);
      } catch (e: any) {
        balance.value = {
          isCache: false,
          priority: 0,
          totalPointBalance: 0,
          totalExpressPoints: 0,
          items: [],
        };
        savedLoyaltyBalance.value = "";
        throw createError({
          message: e?.message || "Ошибка парсинга объекта бонусов",
          fatal: false,
        });
      }
    }
  });

  watch(qrcode, (next) => {
    if (next && next !== savedQrCode.value.code) {
      savedQrCode.value.code = next;
    }
  });

  watch(
    savedUserId,
    (next) => {
      if (next.uuid) {
        profile.value.userId = savedUserId.value.uuid;
      }
    },
    { immediate: true },
  );

  function setSavedUUID(uuid: string) {
    savedUserId.value.uuid = uuid;
  }

  function clearSavedUUID() {
    savedUserId.value.uuid = "";
  }

  // TODO: отрефачить, тут в getQR сейчас по факту сплелось:
  // получание QR,
  // интервал обновления QR раз в 5 минут (отдельным методом надо запускать из app.vue, а то бизнес логика спуталась со слоем API)
  // (кстати походу будет дубль интервала каждый раз когда мы вызывали getQR лол)
  // создание виртуальной карты если ошибка запроса QR...
  async function getQR(isRunRecursive = false) {
    status.qrcode = "pending";
    status.qrcodeError = null;

    try {
      const data = await useDirectFetch<{
        identifier: string;
        totp: string;
      }>(urls.user.qrcode, {
        permissions: {
          jwt: true,
        },
      });

      if (data) {
        profile.value.cardNumber = data.identifier;
        qrcode.value = `H${data.identifier}T${data.totp}`;

        status.qrcode = "success";

        setInterval(async () => {
          try {
            const data = await useDirectFetch<{
              identifier: string;
              totp: string;
            }>(urls.user.qrcode, {
              permissions: {
                jwt: true,
              },
            });

            if (data) {
              profile.value.cardNumber = data.identifier;
              qrcode.value = `H${data.identifier}T${data.totp}`;

              status.qrcode = "success";
            }
          } catch (e) {
            const error = e as FetchError;
            if (
              error?.statusCode &&
              error.statusCode >= HttpCodes.Error4xx
            ) {
              status.qrcode = "error";
              status.qrcodeError =
                error?.data?.code ||
                (error?.statusMessage &&
                  `requestError - ${error?.statusMessage}`) ||
                  (error?.statusText &&
                    `requestError - ${error?.statusText}`) ||
                    "requestError";
            }
          }
        }, QR_TIMEOUT);
      }
    } catch (e) {
      const error = e as FetchError;
      if (
        error?.statusCode &&
        error.statusCode >= HttpCodes.Error4xx
      ) {
        if (!isRunRecursive) {
          try {
            const virtualCardData = await useDirectFetch<{
              identifier: string;
              totp: string;
            }>(urls.user.virtualCardCreate, {
              permissions: {
                jwt: true,
              },
            });
            if (virtualCardData) {
              await getQR(true);
              return;
            }
          } catch (e) {
            console.error(e);
            captureException(e);
          }
        }

        status.qrcode = "error";
        status.qrcodeError =
          error?.data?.code ||
          (error?.statusMessage &&
            `requestError - ${error?.statusMessage}`) ||
            (error?.statusText &&
              `requestError - ${error?.statusText}`) ||
              "requestError";

        await hasError(error);
      }
    }
  }

  async function register(
    patch: Pick<IUserProfile, "firstName" | "birthDate" | "email"> &
      Pick<
        IUserPermissions,
        "isSmsPermitted" | "isEmailPermitted"
      >,
  ) {
    const authStore = useAuthStore();

    status.register = "pending";

    profile.value = {
      ...profile.value,
      firstName: patch.firstName,
      birthDate: patch.birthDate,
      email: patch.email,
    };

    permissions.value.isSmsPermitted = patch.isSmsPermitted;
    permissions.value.isEmailPermitted = patch.isEmailPermitted;

    const requestData = {
      magnitIDCode: authStore.getMagnitId(),
      birthDate: profile.value.birthDate
        ? getFormattedYYYYMMDD(profile.value.birthDate)
        : undefined,
      firstName: profile.value.firstName,
      email: profile.value.email || undefined,
    };

    if (!profile.value.birthDate) {
      delete requestData.birthDate;
    }

    if (!profile.value.email) {
      delete requestData.email;
    }

    try {
      const data = await useDirectFetch<{
        userId: string;
        birthDate?: string; // "1990-01-01"
        lastName?: string;
        email?: string;
        firstName?: string;
        gender?: string;
        phone: string;
        isEmailConfirmed: boolean;
      }>(urls.user.register, {
        gateway: "magnit-id",
        method: "POST",
        body: requestData,
      });

      if (data) {
        profile.value.userId = data.userId;

        send("Register:Success");

        status.register = "success";

        await authStore.login();

        const allPermissions = [
          permissions.value.isSmsPermitted
            ? updatePermissions(
                permissions.value.isSmsPermitted,
                "isSmsPermitted",
              )
            : false,
          permissions.value.isEmailPermitted
            ? updatePermissions(
                permissions.value.isEmailPermitted,
                "isEmailPermitted",
              )
            : false,
        ].filter(Boolean);

        if (allPermissions.length) await Promise.all(allPermissions);
      }
    } catch (e) {
      const error = e as FetchError;
      if (
        error?.statusCode &&
        error.statusCode >= HttpCodes.Error4xx
      ) {
        status.register = "initial";
        throw error.data;
      }
    }
  }

  async function getProfile() {
    status.profile = "pending";

    try {
      const data = await useDirectFetch<{
        userId: string;
        birthDate?: string; // "1990-01-01"
        lastName?: string;
        email?: string;
        firstName?: string;
        gender?: string;
        phone: string;
        isEmailConfirmed: boolean;
      }>(urls.user.profile, {
        gateway: "magnit-id",
        method: "GET",
        permissions: {
          jwt: true,
        },
      });

      if (data) {
        const { birthDate, ...etc } = data;

        profile.value = {
          ...profile.value,
          ...etc,
          birthDate: birthDate ? new Date(birthDate) : undefined,
        };

        status.profile = "success";
      }
    } catch (e) {
      const error = e as FetchError;
      if (
        error?.statusCode &&
        error.statusCode >= HttpCodes.Error4xx
      ) {
        status.profile = "error";
      }
    }
  }

  async function getPermissions() {
    status.permissions = "pending";

    try {
      const data = await fetchPermissions();

      if (data) {
        permissions.value = renameKeys(data, userPermissionsApiKeyMap);
        status.permissions = "success";
      }
    } catch (e) {
      const error = e as FetchError;
      if (error?.statusCode && error.statusCode >= HttpCodes.Error4xx) {
        status.permissions = "error";

        await hasError(error);
      }
    }
  }

  async function updatePermissions(
    value: IUserPermissions[IUserPermissionsKeys] | Partial<IUserPermissions>,
    keyName?: IUserPermissionsKeys,
    signal?: AbortSignal,
  ) {
    status.permissionsUpdate = "pending";

    let patch: Partial<IUserPermissions> = {};
    if (keyName && typeof value === "boolean") patch[keyName] = value;
    else patch = { ...value as Partial<IUserPermissions> };

    const transformedPatch = renameKeys(
      patch,
      userPermissionsApiKeyMap,
      true,
    ) as Partial<IUserPermissionsResponse>;

    try {
      const data = await fetchUpdatePermissions(transformedPatch, { signal });

      if (data) {
        permissions.value = renameKeys(data, userPermissionsApiKeyMap);
        status.permissionsUpdate = "success";
      }
    } catch (e) {
      const error = e as FetchError;
      if (error?.statusCode && error.statusCode >= HttpCodes.Error4xx) {
        status.permissionsUpdate = "error";

        await hasError(error);
      }
    }
  }

  async function updateProfile(
    patch: Partial<Record<IProfileKeys, string>>,
  ) {
    let customError = "";

    status.profileUpdate = "pending";

    try {
      const data = await useDirectFetch<{
        userId: string;
        birthDate?: string; // "1990-01-01"
        lastName?: string;
        email?: string;
        firstName?: string;
        gender?: string;
        phone: string;
        isEmailConfirmed: boolean;
      }>(urls.user.profile, {
        gateway: "magnit-id",
        method: "PATCH",
        body: patch,
        permissions: {
          jwt: true,
        },
      });

      if (data) {
        const { birthDate, ...etc } = data;

        profile.value = {
          ...profile.value,
          ...etc,
          birthDate: birthDate ? new Date(birthDate) : undefined,
        };

        status.profileUpdate = "success";
      }
    } catch (e) {
      const error = e as FetchError;
      if (
        error?.statusCode &&
        error.statusCode >= HttpCodes.Error4xx
      ) {
        status.profileUpdate = "error";

        if (error?.data?.code === ErrorCodes.EmailForbidden) {
          customError = "Укажите личную почту";
        }

        await hasError(error);

        return customError;
      }
    }
  }

  async function confirmEmail(email: string) {
    status.emailConfirmation = "pending";

    try {
      const data = await useDirectFetch(urls.user.emailConfirmation, {
        method: "POST",
        gateway: "magnit-id",
        body: { email },
        permissions: {
          jwt: true,
        },
      });

      if (data !== null) {
        status.emailConfirmation = "success";
      }
    } catch (e) {
      const error = e as FetchError;
      if (
        error?.statusCode &&
        error.statusCode >= HttpCodes.Error4xx
      ) {
        status.emailConfirmation = "error";
      }
    }
    return status.emailConfirmation;
  }

  async function getBalance() {
    status.balance = "pending";
    status.balanceError = null;

    try {
      const data = await useDirectFetch<IUserBalance>(
        `${urls.user.balance}`,
        {
          method: "GET",
          permissions: {
            jwt: true,
          },
        },
      );

      if (data) {
        balance.value = data;
        status.balance = "success";

        try {
          savedLoyaltyBalance.value = JSON.stringify(balance.value);
        } catch (e: any) {
          savedLoyaltyBalance.value = "";
          throw createError({
            message: e?.message || "Ошибка сериализации объекта бонусов",
            fatal: false,
          });
        }
      }
    } catch (e) {
      const error = e as FetchError;
      if (
        error?.statusCode &&
        error.statusCode >= HttpCodes.Error4xx
      ) {
        status.balance = "error";
        status.balanceError = error?.data?.code || null;

        await hasError(error);
      }
    }
  }

  async function getCards() {
    status.cardsList = "pending";

    try {
      const data = await useDirectFetch<{
        identifiers: {
          cobranded: boolean;
          id: string;
          identifierNo: string;
          identifierTypeCode: string;
          isIdentifierTypeVirtual: boolean;
          redemptionEnabled: boolean;
          status: string;
          statusName: string;
        }[];
        isMergeEnabled: boolean;
        maxPlasticCards: number;
      }>(urls.user.cards, {
        method: "GET",
        permissions: {
          jwt: true,
        },
      });

      if (data) {
        cards.value = data.identifiers;
        status.cardsList = "success";
      }
    } catch (e) {
      const error = e as FetchError;
      if (
        error?.statusCode &&
        error.statusCode >= HttpCodes.Error4xx
      ) {
        status.cardsList = "error";

        await hasError(error);
      }
    }
  }

  async function mergeCard(patch: {
    cvc: string;
    identifier: string;
    token: string;
  }) {
    // ToDo: для v1 метод еще не рабочий
    status.cardMerge = "pending";
    status.cardMergeError = null;

    try {
      const data = await useDirectFetch(urls.user.cardMerge, {
        method: "POST",
        body: {
          additionalIdentifierCvv: patch.cvc,
          additionalIdentifierNo: patch.identifier,
          card_type: "loyalty",
        },
        permissions: {
          jwt: true,
        },
        headers: {
          "X-Captcha-Token": patch.token,
        },
      });

      if (data.value !== null) {
        status.cardMerge = "success";
      }
    } catch (e) {
      const error = e as FetchError;
      if (
        error?.statusCode &&
        error.statusCode >= HttpCodes.Error4xx
      ) {
        status.cardMerge = "error";
        status.cardMergeError = error.data.code || null;

        await hasError(error);
      }
    }
  }

  return {
    confirmEmail,
    getBalance,
    getCards,
    getProfile,
    setSavedUUID,
    clearSavedUUID,
    updateProfile,
    getPermissions,
    updatePermissions,
    mergeCard,
    register,
    profile,
    cards,
    permissions,
    isAnyPushPermitted,
    balance,
    status,
    getQR,
    qrcode,
  };
});
