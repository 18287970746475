import { useLocalStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import { useRuntimeConfig } from "nuxt/app";
import { FetchError } from "ofetch";
import { captureException } from "@sentry/nuxt";
import type { IAbExperiments, IAbExperimentsFeature } from "~/typings/api/experiments";
import experimentsApi from "~/api/experimentsApi";

interface IExperimentsStatus {
  experiments: "initial" | "pending" | "success" | "error";
}

export const useExperimentsStore = defineStore("experiments", () => {
  const { fetchExperiments } = experimentsApi();
  const experimentsNamespace = useRuntimeConfig().public.experimentsNamespace ?? "";

  const status = reactive<IExperimentsStatus>({
    experiments: "initial",
  });
  const experiments = ref<number[]>([]);
  const features = ref<IAbExperiments["features"]>([]);

  const hasExperiment = (groupId: number) => experiments.value.includes(groupId);
  const hasFeature = (featureName: IAbExperimentsFeature["feature_name"], groupName: IAbExperimentsFeature["group_name"]) => {
    return features.value.some((feature) => feature.feature_name === featureName && feature.group_name === groupName);
  };

  const getExperiments = async () => {
    status.experiments = "pending";

    const savedUserId = useLocalStorage<{
      uuid: string;
    }>(storage.uuid, {
      uuid: "",
    });

    const splitId = savedUserId.value.uuid || "";

    try {
      if (!splitId) return;
      const data = await fetchExperiments(splitId, experimentsNamespace);
      if (data) {
        experiments.value = data?.active_experiments_group_ids ?? [];
        features.value = data?.features ?? [];
        status.experiments = "success";
      }
    } catch (e) {
      const error = e as FetchError;
      captureException(error);
      if (error) {
        status.experiments = "error";
      }
    }
  };

  const getExperimentsIfNeeded = async () => {
    if (["pending", "success"].includes(status.experiments)) {
      return;
    }
    await getExperiments();
  };

  const experimentsForAnalytics = computed<string | null>(() => experiments.value.join(";") || null);

  const hasExperimentProductsRating = computed<boolean>(() => hasFeature("V1_UR_products_rating", "test"));

  return {
    hasExperiment,
    hasFeature,
    hasExperimentProductsRating,
    getExperiments,
    getExperimentsIfNeeded,
    status,
    experimentsForAnalytics,
  };
});
