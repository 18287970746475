import type { FetchOptions } from "ofetch";

interface IRequestParams {
  gateway?: "web" | "magnit-id";
  permissions?: {
    jwt?: boolean;
  };
  method?: FetchOptions["method"];
  headers?: FetchOptions["headers"];
  body?: any;
  params?: any;
  query?: any;
}

// по сути почти как useTransport, только внутри использует обычный не реактивный $fetch
export const useDirectFetch = async <R = unknown>(
  url: string,
  params?: IRequestParams,
  clientName?: string,
) => {
  const config = useRuntimeConfig();

  const { permissions, gateway = "web", headers, ...restParams } = params || {};

  let _headers = getHeaders(headers, config.public.version as string);

  if (gateway === "web") {
    _headers = {
      ..._headers,
      "X-Client-Name": clientName ?? "b1",
      "X-Loyalty-Type": "B1",
    };
  }

  if (permissions?.jwt) {
    const Authorization = await getPermissionJWT();

    _headers = {
      ..._headers,
      Authorization,
    };
  }

  return $fetch<R>(url, {
    baseURL: gateway === "web" ? "/webgate" : "/magnit-id",
    headers: _headers,
    // из-за гемора выцеплять NitroFetchOptions тут пока-что просто as any -_-
    // благо выше в IRequestParams у нас method типизировано окей, что внешних потребителей спасёт от ошибок
    method: restParams.method as any,
    ...restParams,
  });
};
